import { Component, Inject, OnInit, Input, } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";
import { TinymceService } from "@/angular-blocworx/components/page/tinymce.service";

@Component({
    selector: "wysiwyg-for-user-field-in-form",
    templateUrl: "angular-blocworx/components/fields/wysiwyg-for-user/templates/in-form.html",
    styleUrls: ["angular-blocworx/components/page/wysiwyg.component.scss"],
})
export class WysiwygForUserFieldInForm implements OnInit {
    @Input() fc;
    @Input() scanStation;
    @Input() main;
    @Input() field;
    @Input() row;
    @Input() data;
    @Input() last;
    @Input() fieldId;

    editorConfig: any;
    htmlContent: SafeHtml;
    openEditor: boolean = false;

    constructor(@Inject(TinymceService) private tinymceService: TinymceService, @Inject(DomSanitizer) private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        this.scanStation.domIndexes[this.field.field_slug] = this.field.fieldIndex;
        this.htmlContent = this.sanitizeContent(this.field.htmlContent);

        if (this.field.showEditorByDefault == 1) {
            this.openEditor = true;
            this.initializeEditorConfig();
        }
    }

    /**
     *
     * Allows html to be displayed on screen
     *
     */
    public sanitizeContent(content: string): SafeHtml {
        if (!content) {
            return "";
        }
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

    /**
     *
     * Opens the editor
     *
     */
    activateEditor(): void {
        this.openEditor = true;
        this.initializeEditorConfig();
    }

    /**
     *
     * Closes the editor
     *
     */
    deactivateEditor(): void {
        this.openEditor = false;
    }

    /**
     *
     * Displays the tinymce text editor with option for basic editor
     *
     * @private
     */
    private initializeEditorConfig(): void {
        this.tinymceService.setTinyMCETemplate();

        if (this.field.basicEditor == 1) {
            this.editorConfig = this.tinymceService.tinymceOptionsLight;
        } else {
            this.editorConfig = this.tinymceService.tinymceOptions;
        }
    }
}
