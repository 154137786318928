import {
    Component,
    OnInit,
    Injectable,
    Optional,
    Inject,
    Input,
    Injector,
    SimpleChanges,
    AfterViewInit,
} from "@angular/core";
import { PageService } from "@/angular-blocworx/components/page/page.service";
import { DomSanitizer, SafeUrl  } from "@angular/platform-browser";
import { Router, ActivatedRoute, RouterLink, RouterModule } from '@angular/router';

@Component({
    selector: "edit-page-header",
    templateUrl: "angular-blocworx/components/page/edit-page-header/edit-page-header.html",
})
@Injectable()
export class EditPageHeader {
    @Input() page;
    @Input() main;
    @Input() link;
    pageData: any = {};
    sectionsPagesModules: any;
    pageId: any;

    constructor(@Inject(PageService) private PageService: PageService, @Inject(DomSanitizer) private sanitizer: DomSanitizer,
                @Inject(Router) private Router: Router, @Inject(ActivatedRoute) private ActivatedRoute: ActivatedRoute) {

    }

    async ngOnInit(): Promise<void> {
        await this.getPageData();
        this.page.editPageDetailsShow = false;
    }

    /**
     * Updates title and text in real time, subscribes to observable
     */
    async ngAfterViewInit(): Promise<void> {
        this.PageService.currentText.subscribe((text) => {
            this.pageData.description = text;
        });
        this.PageService.currentTitle.subscribe((title) => {
            this.pageData.title = title;
        });
    }

    async getPageData(): Promise<void> {
        const response = await this.PageService.getPageData(this.page.stateName, this.page.pageId, this.page.pageSlug);
        this.page.sectionsPagesModules = response.sectionsPagesModules;
        this.pageData = response.pageData;

        this.page.sectionsPagesModules.forEach((section) => {
            section.links.forEach((link) => {
                if (link.link_type == "module" && this.page.stateName == "edit-page") {
                    link.ui_sref = ['module', link.id, 'edit'];
                }

                if (link.link_type == "module" && (this.page.stateName == "page" || this.page.stateName == "home")) {
                    link.ui_sref = ['module', link.slug];
                }

                if (link.link_type == "page" && this.page.stateName == "edit-page") {
                    link.ui_sref = ['page', link.id, 'edit'];
                }

                if (link.link_type == "page" && (this.page.stateName == "page" || this.page.stateName == "home")) {
                    link.ui_sref = ['page', link.slug];
                }

                if (link.link_type == "url" && this.page.stateName == "edit-page") {
                    link.ui_sref = ['url', link.id, 'edit'];
                }
            });
        });
    }

    /**
     *
     * Allows html to be displayed on screen
     *
     * @param htmlCode
     */
    toTrusted(htmlCode: string): any {
        if (htmlCode) {
            return this.sanitizer.bypassSecurityTrustHtml(htmlCode);
        }
        return "";
    }

    /**
     *
     * Generates a safe, sanitized URL for navigation to a specific page
     *
     * @param slug
     */
    getPageUrl(slug: any): SafeUrl {
        const url  = window.location.protocol + '//' + window.location.host + '/page/' + slug;
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    getRootUrl(): SafeUrl {
        const scheme = window.location.protocol;
        const url = scheme + '//' + window.location.host + '/';
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    getManagePagesUrl(): SafeUrl {
        const scheme = window.location.protocol;
        const url = scheme + '//' + window.location.host + '/manage-pages';
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    getEditParentPageUrl(parentId: any): SafeUrl {
        const scheme = window.location.protocol;
        const url = scheme + '//' + window.location.host + '/edit-page/' + parentId;
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}
