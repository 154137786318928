import { Inject, Injectable, Input } from "@angular/core";
import { Configuration } from "@/angular-blocworx/components/configuration/services/configuration.service";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SeparatorFieldService {

    constructor(@Inject(Configuration) private Configuration: Configuration) {

    }

    /**
     * Returns the initial Parameters for this field.
     */

    getInitialParams() {
        try
        {
            let initialParams = {
                'seperatorDefaultText': {
                    'validation': {
                        'required': false
                    }
                }
            }
            return initialParams;
        } catch (e) {
            console.log(e);
        }

    }

    /**
     * Returns the configuration object for the field component.
     */

    separatorFieldDirective() {
        const version = this.Configuration.getVersion();
        const random = this.Configuration.getRandomValue();
        const templateUrl = `components/field/template/separator/field.html?v=${version}&rand=${random}`;

        return {
            controller: "FieldController",
            restrict: "A",
            templateUrl: templateUrl
        };

    }

    /**
     * Returns the configuration object for the build/edit component.
     */

    separatorFieldBuild() {
        const version = this.Configuration.getVersion();
        const random = this.Configuration.getRandomValue();
        const templateUrl = `components/field/template/separator/build-edit.html?v=${version}&rand=${random}`;

        return {
            controller: "FieldController",
            restrict: "A",
            templateUrl: templateUrl
        };

    }

    public initiateAllFieldParametersFromValues(initialFieldParams: object, defaultFieldParameterObject: object) {
        const keys: string[] = Object.keys(defaultFieldParameterObject);

        for (const key of keys) {

            if (initialFieldParams[key] == null) {
                if (defaultFieldParameterObject[key].subParameters == null) {
                    initialFieldParams[key] = "";
                } else {
                    initialFieldParams[key] = {};
                }
            }

            if (defaultFieldParameterObject[key].subParameters != null) {
                const subParameterKeys: string[] = Object.keys(defaultFieldParameterObject[key].subParameters);
                for (const subParameterKey of subParameterKeys) {
                    if (initialFieldParams[key][subParameterKey] == null) {
                        initialFieldParams[key][subParameterKey] = "";
                    }
                }
            }
        }

        return initialFieldParams;
    }

}
