import {
    Component,
    OnInit,
    Injectable,
    Optional,
    Inject,
    Input,
    Injector,
    SimpleChanges,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OverlayContainer } from "@angular/cdk/overlay";

@Component({
    selector: "replaced-data",
    templateUrl: "angular-blocworx/components/manageData/find-and-replace/replaced-data.html",
})

export class ReplacedData {
    @Input() main;
    filteredResults: any[] = [];


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ReplacedData>,
        @Inject(OverlayContainer) private overlayContainer: OverlayContainer,
    ) {
        this.filteredResults = data.filteredResults;
    }

    async ngOnInit(): Promise<void> {
    }

    /**
     * Closes the dialog popup
     */
    cancel() {
        this.overlayContainer.getContainerElement().classList.remove("blocworx-popup");
        this.dialogRef.close();
    }

}
