import { Component, Inject, Input, OnInit } from "@angular/core";

@Component({
    selector: "manage-data",
    templateUrl: "angular-blocworx/components/manageData/manage-data.html",
    styleUrls: ['angular-blocworx/components/manageData/look-up-data/look-up-data.component.scss'],
})
export class ManageData {
    @Input() main;
    @Input() lc;
    @Input() status;
    @Input() job;
    @Input() data;

    constructor() {}

    ngOnInit() {}
}
