import {Component, Inject, OnInit, Input} from '@angular/core';

@Component({
    selector: 'wysiwyg-create-edit-field-radio-button',
    templateUrl: 'angular-blocworx/components/fields/wysiwyg/templates/create-edit/radio-button.html',
    styleUrls: ["angular-blocworx/components/page/wysiwyg.component.scss"],

})


export class WysiwygCreateEditFieldRadioButton implements OnInit {

    @Input() fc;
    @Input() scanStation;
    @Input() main;
    @Input() field;
    @Input() row;
    @Input() data;

    constructor(

    )
    {

    }

    ngOnInit(): void {

    }

}
