import { Component, Inject, OnInit, Input, Injectable } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "wysiwyg-field-in-form",
    templateUrl: "angular-blocworx/components/fields/wysiwyg/templates/in-form.html",
    styleUrls: ["angular-blocworx/components/page/wysiwyg.component.scss"],
})


export class WysiwygFieldInForm implements OnInit {

    @Input() fc;
    @Input() scanStation;
    @Input() main;
    @Input() field;
    @Input() row;
    @Input() data;

    htmlContent: SafeHtml;

    constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.scanStation.domIndexes[this.field.field_slug] = this.field.fieldIndex;
        this.htmlContent = this.sanitizeContent(this.field.htmlContent);
    }

    /**
     *
     * Allows html to be displayed on screen
     *
     */
    public sanitizeContent(content: string): SafeHtml {
        if (!content) {
            return '';
        }
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }
}
