import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WysiwygFieldServiceBLCXAU {
    constructor() {}

    async getInitialParams() {
        try {
            let initialParams = {
                'htmlContent': {
                    'validation': {
                        'required': false
                    }
                }
            };

            return initialParams;

        } catch (e) {
            console.error(e);
        }
    }
}

