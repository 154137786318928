import { Component, OnInit, Injectable, Optional, Inject, Input, Injector, SimpleChanges } from "@angular/core";
import { PageService } from "@/angular-blocworx/components/page/page.service";
import * as angular from "angular";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Component({
    selector: "edit-page-sections-pages-modules",
    templateUrl: "angular-blocworx/components/page/edit-page-sections-pages-modules/edit-page-sections-pages-modules.html",
})
@Injectable()

export class EditPageSectionsPagesModules {

    @Input() page;
    @Input() main;
    @Input() data;
    @Input() icon;
    @Input() link;
    @Input() status;
    @Input() role;
    @Input() section;
    pageList: any[] = [];
    pageSections: any[] = [];
    rolesAndPermissions: any[] = [];
    stateName: any;
    pageSlug: any;
    urlId: any;
    pageData: any = {};
    sectionsPagesModules: any;
    pagePopUpOpen: any[] = [];
    editSectionName: any[] = [];
    addSubPageShow: any[] = [];
    addModuleShow: any[] = [];
    addUrlLinkShow: any[] = [];
    newRoles: any[] = [];
    newPageOpenAccess: any;
    selectedNewPageRole: any;
    jobStatuses: any;
    moduleName: any;
    newModuleCategory: any;
    newSectionName: any;

    constructor(
        @Inject(PageService) private PageService: PageService,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer

) {}

async ngOnInit(): Promise<void> {
    await this.getPageData();
    await this.page.getJobStatuses();
    await this.getPageSections();
    await this.getRolesAndPermissions();
    await this.getPages();
    await this.getCountPageSections();
    this.newPageOpenAccess=0;

}

async ngAfterViewInit(): Promise<void> {}

async getPageSections(): Promise<void> {
    let response = await this.PageService.getPageSections(this.page.pageId);
    this.pageSections = response.data;

}

async getRolesAndPermissions(): Promise<void> {
    let response = await this.PageService.getRolesAndPermissions();
    this.rolesAndPermissions = response.data;

}

async updatePage(): Promise<void> {
    if (this.pageData.parent_id == "No Parent") {
    this.pageData.parent_id = null;

}

await this.PageService.updatePage(this.pageData);
await this.getPages();
alert("Page Details Now Updated");

}

async getPages(): Promise<void> {
    let response = await this.PageService.getPages();
    this.pageList = response.data;
    this.pagePopUpOpen = [];

    if (typeof this.pageList === "object" && this.pageList !== null) {
    for (let key of Object.keys(this.pageList)) {
        let page = this.pageList[key];
        if (page) {
            page.countPageSections = await this.PageService.getCountPageSections(page.id, page.slug);

        }

    }

}

}

async getCountPageSections(): Promise<void> {
    let response = await this.PageService.getCountPageSections(this.page.pageId, this.page.pageSlug);
    return response;

}

async getPageData(): Promise<void> {

    const response = await this.PageService.getPageData(this.page.stateName, this.page.pageId, this.page.pageSlug);
    this.page.sectionsPagesModules = response.sectionsPagesModules;
    this.pageData = response.pageData;
    this.page.sectionsPagesModules.forEach((section) => {
        section.links.forEach((link) => {
            if (link.link_type == "module" && this.page.stateName == "edit-page") {
                link.ui_sref = "edit-job-module({" + 'id:"' + link.id + '"})';
            }
            if (link.link_type == "module" && (this.page.stateName == "page" || this.page.stateName == "home")) {
                link.ui_sref = "job-module({" + 'uniqueModuleSlug:"' + link.slug + '"})';
            }
            if (link.link_type == "page" && this.page.stateName == "edit-page") {
                link.ui_sref = "edit-page({" + 'pageId:"' + link.id + '"})';
            }

            if (link.link_type == "page" && (this.page.stateName == "page" || this.page.stateName == "home")) {
                link.ui_sref = "page({" + 'pageSlug:"' + link.slug + '"})';
            }

            if (link.link_type == "url" && this.page.stateName == "edit-page") {
                link.ui_sref = "edit-url({" + 'urlId:"' + link.id + '"})';
            }
        });
    });
}

startSections(sectionId: any): void {
    this.page.editSectionName[sectionId] = false;
    this.page.addSubPageShow[sectionId] = false;
    this.page.addModuleShow[sectionId] = false;
    this.page.addUrlLinkShow[sectionId] = false;

}

openEditSectionName(sectionId: any): void {
    this.page.editSectionName[sectionId] = !this.page.editSectionName[sectionId];
    Object.keys(this.page.addSubPageShow).forEach((key) => (this.page.addSubPageShow[key] = false));
    Object.keys(this.page.addModuleShow).forEach((key) => (this.page.addModuleShow[key] = false));
    Object.keys(this.page.addUrlLinkShow).forEach((key) => (this.page.addUrlLinkShow[key] = false));

}

openAddSubPageShow(sectionId: any): void {
    this.page.addSubPageShow[sectionId] = !this.page.addSubPageShow[sectionId];
    Object.keys(this.page.editSectionName).forEach((key) => (this.page.editSectionName[key] = false));
    Object.keys(this.page.addModuleShow).forEach((key) => (this.page.addModuleShow[key] = false));
    Object.keys(this.page.addUrlLinkShow).forEach((key) => (this.page.addUrlLinkShow[key] = false));

}

openAddModuleShow(sectionId: any): void {
    this.page.addModuleShow[sectionId] = !this.page.addModuleShow[sectionId];
    Object.keys(this.page.addSubPageShow).forEach((key) => (this.page.addSubPageShow[key] = false));
    Object.keys(this.page.editSectionName).forEach((key) => (this.page.editSectionName[key] = false));
    Object.keys(this.page.addUrlLinkShow).forEach((key) => (this.page.addUrlLinkShow[key] = false));

}

openAddUrlLinkShow(sectionId: any): void {
    this.page.addUrlLinkShow[sectionId] = !this.page.addUrlLinkShow[sectionId];
    Object.keys(this.page.addSubPageShow).forEach((key) => (this.page.addSubPageShow[key] = false));
    Object.keys(this.page.addModuleShow).forEach((key) => (this.page.addModuleShow[key] = false));
    Object.keys(this.page.editSectionName).forEach((key) => (this.page.editSectionName[key] = false));
}

async updatePageSectionName(newSectionName: string, sectionID: any): Promise<void> {
    await this.PageService.updatePageSectionName(newSectionName, sectionID);
    await this.getPageData();
}

async deletePageSection(sectionID: any): Promise<void> {
    await this.PageService.deletePageSection(sectionID, this.page.pageId);
    await this.getPageData();
    alert("Section deleted");
}

async addSubPage(subPageName: string, sectionID: any, orderID: any): Promise<void> {
    await this.PageService.addSubPage(subPageName, sectionID, this.page.pageId,  orderID,  this.page.newLinkIcon, this.newRoles, this.newPageOpenAccess);
    await this.getPageData();
}

async removeRoleFromNewPage(roleId: any): Promise<void> {
    const index = this.newRoles.indexOf(roleId);
    if (index !== -1) {
    this.newRoles.splice(index, 1);
}

}

async addModule(moduleName: string, newModuleCategory: string, sectionID: any, orderID: any): Promise<void> {
    if (newModuleCategory) {
        await this.PageService.addModule(moduleName, newModuleCategory, sectionID, this.page.pageId, orderID, this.page.newLinkIcon);
        await this.getPageData();
    } else {
        alert("Please select a module category");
}

}

async addLinkUrl(newLinkName: string, newLinkUrl: string, sectionID: any, orderID: any, newRoles: any[], newTab: boolean): Promise<void> {
    await this.PageService.addLinkUrl(newLinkName, newLinkUrl, sectionID, this.page.pageId, orderID, this.page.newLinkIcon, newRoles, newTab);
    await this.getPageData();
}

deleteLinkUrl(linkId): void {
    const userConfirmed = confirm("Are you sure you want to delete this link?");
    if (userConfirmed) {
        this.page.deleteLinkUrl(linkId);
    }
}

async newPageOpenAccessCheckbox(isChecked: boolean, newPageOpenAccess: any): Promise<void> {
    newPageOpenAccess = isChecked ? 1 : 0;
}

removeRoleFromExistingPage(roleId: any): void {
    const index = this.pageData.roles.indexOf(roleId);
    if (index !== -1) {
    this.pageData.roles.splice(index, 1);
}
}

getPageUrl(uiSref: any): SafeUrl {
        if (typeof uiSref !== 'string' || !uiSref) {
            return this.sanitizer.bypassSecurityTrustUrl(`${window.location.protocol}//${window.location.host}`);
        }
        // Parse the ui_sref to determine the correct URL
        const url = this.convertUiSrefToUrl(uiSref);
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

// Convert ui_sref values to URLs based on your application's logic
    convertUiSrefToUrl(uiSref: any): string {
        if (typeof uiSref !== 'string') {
            return `${window.location.protocol}//${window.location.host}`;
        }
        const baseUrl = `${window.location.protocol}//${window.location.host}`;

        // Define your URL patterns
        if (uiSref.startsWith('edit-job-module')) {
            const id = this.extractParameter(uiSref, 'id');
            return `${baseUrl}/module/${id}/edit`;
        } else if (uiSref.startsWith('edit-page')) {
            const pageId = this.extractParameter(uiSref, 'pageId');
            return `${baseUrl}/page/${pageId}/edit`;
        } else if (uiSref.startsWith('job-module')) {
            const uniqueModuleSlug = this.extractParameter(uiSref, 'uniqueModuleSlug');
            return `${baseUrl}/module/${uniqueModuleSlug}/view`;
        } else if (uiSref.startsWith('page')) {
            const pageSlug = this.extractParameter(uiSref, 'pageSlug');
            return `${baseUrl}/page/${pageSlug}/view`;
        } else if (uiSref.startsWith('edit-url')) {
            const urlId = this.extractParameter(uiSref, 'urlId');
            return `${baseUrl}/url/${urlId}/edit`;
        }

        // Default or fallback URL
        return baseUrl;
    }

// Extract parameter from ui_sref string
extractParameter(uiSref: string, paramName: string): string {
    const regex = new RegExp(`${paramName}:"([^"]+)"`);
    const match = uiSref.match(regex);
    return match ? match[1] : '';
}

}
