import { Component, Inject, OnInit, Input, Injectable } from "@angular/core";
import { TinymceService } from "@/angular-blocworx/components/page/tinymce.service";

@Component({
    selector: "wysiwyg-create-edit-field-popup",
    templateUrl: "angular-blocworx/components/fields/wysiwyg/templates/create-edit/popup.html",
    styleUrls: ["angular-blocworx/components/page/wysiwyg.component.scss"],
})
@Injectable()
export class WysiwygCreateEditFieldPopup implements OnInit {
    @Input() fc;
    @Input() scanStation;
    @Input() main;
    @Input() field;
    @Input() row;
    @Input() data;
    editorConfigTwo: any;

    constructor(@Inject(TinymceService) private TinymceService: TinymceService) {}

    ngOnInit(): void {
    }

}
