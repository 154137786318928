import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Inject } from "@angular/core";
import { TinymceService } from "@/angular-blocworx/components/page/tinymce.service";

@Component({
    selector: "tiny-mce-container",
    template: `
        <div class="tiny-mce-container">
            <div>
                <editor [init]="editorConfigTwo" [(ngModel)]="contentValue"></editor>
            </div>
        </div>
    `,
    styleUrls: ["angular-blocworx/components/page/wysiwyg.component.scss"],
})
export class TinyMceContainer implements OnInit {
    @Input() editorConfigTwo: any;
    @Input() content: string;

    // Output event emitter for two-way binding: emits changes to the editor's content
    @Output() contentChange = new EventEmitter<string>();

    /**
     * Getter for the editor's content value.
     * Used to bind the current value of `content` to the editor.
     */
    get contentValue() {
        return this.content;
    }

    /**
     * Setter for the editor's content value.
     * Updates the `content` property and emits the updated value to the parent via `contentChange`.
     * @param value - The new content value from the TinyMCE editor
     */
    set contentValue(value: string) {
        this.content = value;
        this.contentChange.emit(value);
    }

    constructor(@Inject(TinymceService) private TinymceService: TinymceService) {}

    ngOnInit(): void {
        this.editorConfigTwo = this.TinymceService.tinymceOptions;
    }
}
