import { Component, Inject, OnInit, Input } from '@angular/core';
import {SeparatorFieldService} from "@/angular-blocworx/components/fields/separator/separator.service";

@Component({
    selector: 'separator-field-create-edit-popup',
    templateUrl: 'angular-blocworx/components/fields/separator/templates/create-edit/popup.html',
    styleUrls: ['angular-blocworx/components/fields/separator/separator.component.scss'],
})

export class SeparatorNewFieldCreateEditPopup implements OnInit {

    @Input() fc;
    @Input() scanStation;


    constructor(@Inject(SeparatorFieldService) private SeparatorFieldService: SeparatorFieldService) { }

    async ngOnInit(): Promise<void> {

        this.scanStation.activeField = 'seperator';
        await this.initialiseFieldParametersFromValues();

    }

    async initialiseFieldParametersFromValues(): Promise<void> {
        this.scanStation.externalJobs = {};
        const defaultFieldParameterObject: object = this.SeparatorFieldService.getInitialParams();
        this.fc.initialFieldParams = this.SeparatorFieldService.initiateAllFieldParametersFromValues(this.fc.initialFieldParams, defaultFieldParameterObject);
    }

}
