import { NgModule } from '@angular/core';
import { RouterModule, Routes, RouterLink } from '@angular/router';
import { EditPageHeader } from "@/angular-blocworx/components/page/edit-page-header/edit-page-header.component";
import { EditPageSectionsPagesModules } from "@/angular-blocworx/components/page/edit-page-sections-pages-modules/edit-page-sections-pages-modules.component";

const routes: Routes = [
    {
        path: 'page/:pageSlug',
        component: EditPageHeader,
    },
    {
        path: '',
        component: EditPageHeader,
    },
    {
        path: 'manage-pages',
        component: EditPageHeader,
    },
    {
        path: 'edit-page/:parentId',
        component: EditPageHeader,
    },
    {
        path: 'module/:id/edit',
        component: EditPageSectionsPagesModules,
    },
    {
        path: 'page/:id/edit',
        component: EditPageSectionsPagesModules,
    },
    {
        path: 'url/:urlId/edit',
        component: EditPageSectionsPagesModules
    },
    {
        path: 'module/:uniqueModuleSlug',
        component: EditPageSectionsPagesModules,
    },
    {
        path: 'page/:pageSlug',
        component: EditPageSectionsPagesModules,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
