import {Component, Inject, OnInit, Input} from '@angular/core';

@Component({
    selector: 'wysiwyg-for-user-field-in-data',
    templateUrl: 'angular-blocworx/components/fields/wysiwyg-for-user/templates/in-data.html',
    styleUrls: ["angular-blocworx/components/page/wysiwyg.component.scss"],
})


export class WysiwygForUserFieldInData implements OnInit {

    @Input() fc;
    @Input() scanStation;
    @Input() main;
    @Input() field;
    @Input() row;
    @Input() data;

    constructor(


    ) {
    }

    ngOnInit(): void {

    }


}
