import {
    Component,
    OnInit,
    Injectable,
    Optional,
    Inject,
    Input,
    Injector,
    SimpleChanges,
    ChangeDetectionStrategy,
} from "@angular/core";
import { ManageDataService } from "@/angular-blocworx/components/manageData/manageData.service";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import {
    ReplaceConfirmation,
} from "@/angular-blocworx/components/manageData/find-and-replace/replace-confirmation.component";
import { OverlayContainer } from "@angular/cdk/overlay";

@Component({
    selector: "find-and-replace",
    templateUrl: "angular-blocworx/components/manageData/find-and-replace/find-and-replace.html",
})

export class FindandReplace {
    @Input() main;
    valueToSearch: string = "";
    valueToReplace: string = "";
    filteredResults: any[] = [];

    constructor(
        @Inject(ManageDataService) private ManageDataService: ManageDataService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(OverlayContainer) private overlayContainer: OverlayContainer,
    ) {

    }

    async ngOnInit(): Promise<void> {
    }

    /**
     *
     * Opens dialog popup and pass the values of valueToSearch and valueToReplace to that dialogs component
     *
     */
    openDialog() {

        const dialogRef = this.dialog.open(ReplaceConfirmation, {
            data: {
                valueToSearch: this.valueToSearch,
                valueToReplace: this.valueToReplace,
            },
        });

        this.overlayContainer.getContainerElement().classList.add('blocworx-popup');

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
            this.overlayContainer.getContainerElement().classList.remove('blocworx-popup');
        });
    }
}
