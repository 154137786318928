import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {lastValueFrom} from "rxjs";


@Injectable({
    providedIn: 'root'
})

export abstract class ScanStationService {
    protected apiUrl: string;
    protected evervaultEnabledApiUrl: string;
    protected scanStationInformation: any = null;
    protected scanStationInformationProcessedID: any;

    private getScanStationInformationFromComponent: Function;
    private initialiseScanStationObjectPropertiesBeforeProcessedFields: Function;
    private initialiseScanStationObjectPropertiesAfterProcessedFields: Function;

    protected constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {
        this.configuration.ngOnInit();
        this.apiUrl = this.configuration.getApiUrl();
        this.evervaultEnabledApiUrl = this.configuration.getEvervaultInboundRelayUrl();
    }

    public getScanStationDetails = (scanStationID): Promise<any> => {
        const headers = this.request.getHeaders()

        const body = {
            scanStationID,
        }
        const response = this.http.post(`${this.apiUrl}get-scan-station-details`, body, {headers});
        return lastValueFrom(response)
    }
    deleteData = async (entryID): Promise<any> => {

        let apiBase: string = this.apiUrl + 'delete-data';

        let params = new HttpParams()
            .set('entryID', entryID);

        let headers = this.request.getHeaders();

        const response = this.http.delete(apiBase, {params, headers});

        return lastValueFrom(response);
    }

    async getScanStationInformation(id: string, source: string, reload = false): Promise<any> {
        try {
            // Check if data needs to be reloaded
            if (this.needsReload(id, reload, source)) {
                const apiBase: string = this.apiUrl + 'get-scan-station-information';
                const params = new HttpParams().set('id', id).set('source', source);
                const headers = this.request.getHeaders(); // Ensure this method exists and returns valid headers

                const response = await lastValueFrom(
                    this.http.get<any>(apiBase, { params, headers }) // Define the expected response type instead of any, if possible
                );

                this.scanStationInformation = response;
                this.scanStationInformationProcessedID = response.stationDetails.id;
            }

            return this.scanStationInformation;
        } catch (error) {
            console.error('Error fetching scan station information:', error);
            // Handle the error appropriately
            throw error; // or return a default value
        }
    }

    private needsReload(id: string, reload: boolean, source: string): boolean {
        return this.scanStationInformation == null ||
            this.scanStationInformationProcessedID !== id ||
            reload ||
            source === 'edit-bloc';
    }

    public setGetScanStationInformationComponent(fn: Function) {
        this.getScanStationInformationFromComponent = fn;
    }

    public callGetScanStationInformationComponentFunction(id, source, reload = false) {
        return this.getScanStationInformationFromComponent(id, source, reload);
    }

    public setInitialiseScanStationObjectPropertiesBeforeProcessedFields(fn: Function) {
        this.initialiseScanStationObjectPropertiesBeforeProcessedFields = fn;
    }

    public callInitialiseScanStationObjectPropertiesBeforeProcessedFields() {
        return this.initialiseScanStationObjectPropertiesBeforeProcessedFields();
    }

    public setInitialiseScanStationObjectPropertiesAfterProcessedFields(fn: Function) {
        this.initialiseScanStationObjectPropertiesAfterProcessedFields = fn;
    }

    public callInitialiseScanStationObjectPropertiesAfterProcessedFields(data) {
        return this.initialiseScanStationObjectPropertiesAfterProcessedFields(data);
    }

    public getSingleRecordByRecordId = async (recordId, restrictByFieldSlugs = []): Promise<any> => {

        let headers = this.request.getHeaders();

        const params = new HttpParams().set('recordId', recordId)
            .set('restrictByFieldSlugs', JSON.stringify(restrictByFieldSlugs));

        let response = this.http.get(
            `${this.evervaultEnabledApiUrl}get-single-record-by-record-id`,
            {params, headers}
        );

        return lastValueFrom(response)
    }

    /**
     * calls the apply-autogenerate-replacements endpoint which replaces all temporary autogenerated values with the actual values
     * @param autogenerateReplacements
     */
    public applyAutogenerateReplacements =  (autogenerateReplacements):Promise<any> => {
        const headers = this.request.getHeaders()
        const body = {
            autogenerateReplacements,
        }

        const response = this.http.post(`${this.apiUrl}apply-autogenerate-replacements`, body, {headers});
        return lastValueFrom(response)

    }

}
