import { Injectable, Inject } from "@angular/core";
import angular from "angular";
import { Configuration } from "@/angular-blocworx/components/configuration/services/configuration.service";
import { RequestService } from "@/angular-blocworx/components/configuration/services/request.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, lastValueFrom } from "rxjs";

export interface SharedState {
    noDataFound: boolean;
    dataManagerData: any[];
    dataToLookUp: string;
}

@Injectable({
    providedIn: "root",
})

export class ManageDataService {

    private initialState: SharedState = {
        noDataFound: false,
        dataManagerData: [],
        dataToLookUp: "default"
    };

    protected apiUrl: string;
    private stateSubject: BehaviorSubject<SharedState> = new BehaviorSubject<SharedState>(this.initialState);

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService,
    ) {
        this.configuration.ngOnInit();
        this.apiUrl = this.configuration.getApiUrl();
    }

    // State Management
    getState(): Observable<SharedState> {
        return this.stateSubject.asObservable();
    }

    setState(newState: Partial<SharedState>): void {
        const currentState = this.stateSubject.value;
        this.stateSubject.next({ ...currentState, ...newState });
    }

    // HTTP REQUESTS
    /**
     *
     * Replace values in ScanStationData entries based on provided search and replacement values
     *
     * @param valueToSearch
     * @param valueToReplace
     */
    public replaceValue = async (valueToSearch, valueToReplace): Promise<any> => {
        let headers = this.request.getHeaders();
        let params = { valueToSearch, valueToReplace };
        let response = this.http.post(`${this.apiUrl}replace-values`, params, { headers });
        return lastValueFrom(response);
    };


    /**
     *
     * Sends an email to the specified address with the valueToSearch, valueToReplace, and a list of records to be replaced
     *
     * @param email
     * @param valueToSearch
     * @param valueToReplace
     * @param filteredResults
     */
    public replaceConfirmation = async (email: string, valueToSearch: string, valueToReplace: string, filteredResults): Promise<any> => {
        let headers = this.request.getHeaders();
        let params = { emailReplaceConfirm: email, valueToSearch, valueToReplace, filteredResults };
        let response = this.http.post(`${this.apiUrl}replace-confirmation`, params, { headers });
        return lastValueFrom(response);
    };

    public lookUpData = async (dataToLookUp, jobToLookUp, filterSlugToFilterBy, specificSearch = false) : Promise<any> => {
        let headers = this.request.getHeaders();
        const params: any = { dataToLookUp, filterSlugToFilterBy, specificSearch };

        if (jobToLookUp) {
            params.jobToLookUp = jobToLookUp;
        }

        let response = this.http.get(`${this.apiUrl}look-up-data`, {params, headers});
        return lastValueFrom(response);
    };

    public searchByFieldName = async (fieldNameToSearch) : Promise<any> => {
        let headers = this.request.getHeaders();
        const params = { fieldNameToSearch };
        let response = this.http.get(`${this.apiUrl}search-field-by-name`, {params, headers});
        return lastValueFrom(response)
    };

    public getModuleCategory = async () : Promise<any> => {
        let headers = this.request.getHeaders();
        const params = { };
        let response = this.http.get(`${this.apiUrl}get-job-statuses`, {params, headers});
        return lastValueFrom(response)
    };

    public getUsersJobs = async (sortBy, master, sortByOrder, jobTypeToFilterBy, resultLimit, currentPage, stateName): Promise<any> => {
        let headers = this.request.getHeaders();

        const params: any = {
            sortBy,
            sortByOrder,
            jobTypeToFilterBy,
            resultLimit,
            currentPage
        };

        if (typeof master !== 'undefined') {
            params.master = master;
        }

        if (stateName !== 'someState') {
            params.stateName = stateName;
        }

        let response = this.http.get(`${this.apiUrl}get-users-jobs`, { params, headers });
        return lastValueFrom(response);
    };

    public updateData = async (entryID, newData, stationID): Promise<any> => {
        let headers = this.request.getHeaders();
        let params = { entryID, newData, stationID };
        let response = this.http.post(`${this.apiUrl}update-data`, params, { headers });
        return lastValueFrom(response);
    };

    public deleteData = async (entryID: string): Promise<any> => {
        const headers = this.request.getHeaders();
        const params = { entryID };
        const response = this.http.delete(`${this.apiUrl}delete-data`, {
            headers: headers,
            params: params
        });
        return await lastValueFrom(response);
    };

}
