import { Component, OnInit, Injectable, Optional, Inject, Input, Injector, SimpleChanges, } from "@angular/core";
import { PageService } from "@/angular-blocworx/components/page/page.service";
import { ElementRef } from "@angular/core";
import { Editor } from "tinymce";
import { TinymceService } from "@/angular-blocworx/components/page/tinymce.service";

@Component({
    selector: "edit-page-details",
    templateUrl: "angular-blocworx/components/page/edit-page-details/edit-page-details.html",
    styleUrls: ["angular-blocworx/components/page/wysiwyg.component.scss"],
})
@Injectable()
export class EditPageDetails {
    @Input() page;
    @Input() main;
    pageList: any;
    pageSections: any[] = [];
    rolesAndPermissions: any;
    pageId: any;
    pageData: any;
    sectionsPagesModules: any;
    pagePopUpOpen: any;
    editorConfig: any;
    text: string = "";
    selectedParent: string;
    selectedSectionName: string;

    constructor(@Inject(PageService) private PageService: PageService, @Inject(TinymceService) private TinymceService: TinymceService) {
    }

    async ngOnInit(): Promise<void> {
        await this.getPageData();
        this.TinymceService.setTinyMCETemplate();
        this.editorConfig = this.TinymceService.tinymceOptions;
        await this.getPageSections();
        await this.getRolesAndPermissions();
        await this.getPages();
        await this.getCountPageSections();

    }

    async ngAfterViewInit(): Promise<void> {
        if (this.pageData && this.pageData.description) {
            this.onTextChange(this.pageData.description);
        }
    }

    /**
     *
     * Updates text in real time using observable
     *
     * @param value
     */
    onTextChange(value: string) {
        this.PageService.updateText(value);
        this.pageData.description = value;
    }


    /**
     *
     * Updates title in real time using observable
     *
     * @param value
     */
    onTitleChange(value: string) {
        this.PageService.updateTitle(value);
        this.pageData.title = value;
    }

    /**
     * Changes the Icon
     */
    changeIcon(): void {
        this.page.showIconBox = true;
        this.page.editingModuleIcon = true;
        this.page.editingNewLinkIcon = false;
    }

    /**
     *
     * Moves the page into the section selected
     *
     * @param sectionId
     */
    onSectionChange(sectionId: string) {
        const selectedSection = this.pageSections.find(section => section.id === sectionId);
        if (selectedSection) {
            this.selectedSectionName = selectedSection.name;
        }
    }

    onParentPageChange(parentId: string) {

        const selectedPage = this.page.pageList[parentId];

        if (selectedPage) {
            this.selectedParent = selectedPage.title;
        }
        this.pageData.page_section_id = "0";

        this.getPageSections();
    }

    async getPageSections(): Promise<void> {
        const response = await this.PageService.getPageSections(this.page.pageData.parent_id);
        this.pageSections = response.sections;
    }

    async getRolesAndPermissions(): Promise<void> {
        let response = await this.PageService.getRolesAndPermissions();
        this.rolesAndPermissions = response.data;
    }

    async updatePage(): Promise<void> {
        try {
            if (this.pageData.parent_id === "No Parent") {
                this.pageData.parent_id = null;
            }

            this.pageData.icon = this.page.pageData.icon;

            this.selectedParent = this.pageData.parent_id;

            this.selectedSectionName = this.pageData.page_section_id;

            await this.PageService.updatePage(this.pageData);
            await this.getPages();

            alert("Page Details Now Updated");
        } catch (e) {
            console.error(e);
        }
    }

    async getPages(): Promise<void> {
        let response = await this.PageService.getPages();
        this.pageList = response.data;
        this.pagePopUpOpen = [];

        if (typeof this.pageList === "object" && this.pageList !== null) {
            for (let key of Object.keys(this.pageList)) {
                let page = this.pageList[key];
                if (page) {
                    page.countPageSections = await this.PageService.getCountPageSections(page.id, page.slug);
                }
            }
        }
    }

    async getCountPageSections(): Promise<void> {
        let response = await this.PageService.getCountPageSections(this.page.pageId, this.page.pageSlug);
        return response;
    }

    async getPageData(): Promise<void> {
        const response = await this.PageService.getPageData(this.page.stateName, this.page.pageId, this.page.pageSlug);
        this.page.sectionsPagesModules = response.sectionsPagesModules;
        this.pageData = response.pageData;

        this.page.sectionsPagesModules.forEach((section) => {
            section.links.forEach((link) => {
                if (link.link_type == "module" && this.page.stateName == "edit-page") {
                    link.ui_sref = ["module", link.id, "edit"];
                }

                if (link.link_type == "module" && (this.page.stateName == "page" || this.page.stateName == "home")) {
                    link.ui_sref = ["module", link.slug];
                }

                if (link.link_type == "page" && this.page.stateName == "edit-page") {
                    link.ui_sref = ["page", link.id, "edit"];
                }

                if (link.link_type == "page" && (this.page.stateName == "page" || this.page.stateName == "home")) {
                    link.ui_sref = ["page", link.slug];
                }

                if (link.link_type == "url" && this.page.stateName == "edit-page") {
                    link.ui_sref = ["url", link.id, "edit"];
                }
            });
        });
    }

    /**
     *
     * Controls whether the Manage Page Roles dropdown is displayed or not on screen
     *
     * @param isChecked
     * @param pageData
     */
    async openAccessCheckbox(isChecked: boolean, pageData: any): Promise<void> {
        pageData.open_access = isChecked ? 1 : 0;
    }

    /**
     *
     * Allows user to remove roles after they have been added
     *
     * @param roleId
     */
    removeRoleFromExistingPage(roleId: any): void {
        const index = this.pageData.roles.indexOf(roleId);
        if (index !== -1) {
            this.pageData.roles.splice(index, 1);
        }
    }
}
