import { Inject, Injectable } from "@angular/core";
import { Configuration } from "@/angular-blocworx/components/configuration/services/configuration.service";
import {
    IsVisibleService
} from "@/angular-blocworx/components/fields/wysiwyg-for-user/is-visible.service";

@Injectable({
    providedIn: "root",
})


export class WysiwygForUserFieldServiceBLCXAU {

    trueOrFalse: boolean = true;

    constructor(@Inject(Configuration) private Configuration: Configuration,
                @Inject(IsVisibleService) private IsVisibleService: IsVisibleService) {

        this.updateTrueOrFalse();
    }


    updateTrueOrFalse() {
        this.trueOrFalse = this.IsVisibleService.getValidationRequired();
    }

    /**
     * Returns the initial Parameters for this field.
     */
    async getInitialParams() {
        try {
            const initialParams = {
                basicEditor: {
                    validation: {
                        required: false,
                    },
                },
                openEditorText: {
                    validation: {
                        required: this.trueOrFalse,
                        validationResponse: 'Please choose a default text for the button that opens the editor, e.g. "Open Editor"',
                        requiredType: 'stringMustNotBeEmpty',
                    },
                },
                showEditorByDefault: {
                    validation: {
                        required: false,
                    },
                },
                hideEditButton: {
                    validation: {
                        required: false,
                    },
                },
                hideContentDisplay: {
                    validation: {
                        required: false,
                    },
                },
            };
            return initialParams;
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Returns the configuration object for the field component.
     */
    wysiwygForUserFieldFieldDirective() {
        const version = this.Configuration.getVersion();
        const random = this.Configuration.getRandomValue();
        const templateUrl = `components/field/template/wysiwyg-for-user/field.html?v=${version}&rand=${random}`;

        return {
            selector: 'app-wysiwyg-for-user-field',
            templateUrl: templateUrl,
            controller: 'FieldController',
            restrict: 'A',
        };
    }

    /**
     * Returns the configuration object for the print component.
     */
    wysiwygForUserFieldFieldPrintDirective() {
        const version = this.Configuration.getVersion();
        const random = this.Configuration.getRandomValue();
        const templateUrl = `components/field/template/wysiwyg-for-user/print.html?v=${version}&rand=${random}`;

        return {
            selector: 'app-wysiwyg-for-user-field-print',
            templateUrl: templateUrl,
            controller: 'FieldController',
            restrict: 'A',
        };
    }

    /**
     * Returns the configuration object for the build/edit component.
     */
    wysiwygForUserFieldBuild() {
        const version = this.Configuration.getVersion();
        const random = this.Configuration.getRandomValue();
        const templateUrl = `components/field/template/wysiwyg-for-user/build-edit.html?v=${version}&rand=${random}`;

        return {
            selector: 'app-wysiwyg-for-user-field-build',
            templateUrl: templateUrl,
            controller: 'FieldController',
            restrict: 'A',
        };
    }
}


