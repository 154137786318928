import {Injectable, Inject} from '@angular/core';
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {HttpClient, HttpParams} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {AppSettingService} from "@/angular-blocworx/components/admin/app-setting/app-setting.service";
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class DataEditHistoryService {
    apiUrl: string;
    evervaultEnabledApiUrl: string;
    private clearEditHistoryData: Function;

    constructor(@Inject(HttpClient) private http: HttpClient,
                @Inject(Configuration) private configuration: Configuration,
                @Inject(RequestService) private request: RequestService,
                @Inject(AppSettingService) private appSettingService: AppSettingService) {

        this.apiUrl = this.configuration.getApiUrl();
        this.evervaultEnabledApiUrl = this.configuration.getEvervaultInboundRelayUrl();

    }



    /**
     * This gets the edit history for a single record by the stationDataID which is the id of the record in scan_station_data
     */

    public getDataEditHistoryForSingleRecord = async (stationDataId) : Promise<any> => {

        let headers = this.request.getHeaders();

        const params = new HttpParams().set('stationDataId', stationDataId);

        let response = this.http.get(
            `${this.evervaultEnabledApiUrl}get-data-edit-history-for-single-record`,
            {params, headers}
        );

        return lastValueFrom(response)
    }

    /** This extract a single edit history record from the array of edit history records
     *
     * @param dataEditHistoryForSingleRecord
     * @param dataEditHistoryId
     */
    public getSingleDataEditHistory = async (dataEditHistoryForSingleRecord, dataEditHistoryId) => {

        for (let i = 0; i < dataEditHistoryForSingleRecord.length; i++) {
            if (dataEditHistoryForSingleRecord[i].data_edit_history_id === dataEditHistoryId) {
                return dataEditHistoryForSingleRecord[i];
            }
        }
    }

    /**
     * These functions allow us to call functions in the parent component, this means they can be called
     * from any other component or angularjs controller
     * @param fn
     */
    public setTriggerClearEditHistoryBoxData(fn: Function) {
        this.clearEditHistoryData = fn;
    }

    public callTriggerClearEditHistoryBoxData() {
        if (this.clearEditHistoryData) {
            return this.clearEditHistoryData();
        }
    }
}