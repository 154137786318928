import { Injectable, Inject } from "@angular/core";
import { Editor } from "tinymce";

@Injectable({
    providedIn: "root",
})
export class TinymceService {
    darkSkinApplied: boolean = true;

    constructor() {}

    tinymceOptions: any = {
        license_key: "gpl",
        invalid_elements: "input,textarea,select,option",
        paste_data_images: true,
        plugins:
            "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help emoticons",
        imagetools_cors_hosts: ["picsum.photos"],
        menubar: "file edit view insert format tools table help",
        toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl",
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        content_css: [
            "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i",
            "//www.tiny.cloud/css/codepen.min.css",
            "https://testmaster.blocworx.local/css/externals.css",
        ],
        content_style: `
        .skin-setting-dark.mce-content-body { background-color: #023038; color: #ffffff; }
        .skin-setting-bright.mce-content-body { background-color: #fff; color: #626262; }
        `,
        link_list: [
            { title: "My page 1", value: "http://www.tinymce.com" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
            { title: "My page 1", value: "http://www.tinymce.com" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        file_picker_callback: (callback, value, meta) => {
            if (meta.filetype === "image") {
                callback("https://www.google.com/logos/google.jpg", { alt: "My alt text" });
            }
            if (meta.filetype === "media") {
                callback("movie.mp4", {
                    source2: "alt.ogg",
                    poster: "https://www.google.com/logos/google.jpg",
                });
            }
            if (meta.filetype === "file") {
                callback("https://www.google.com/logos/google.jpg", { text: "My text" });
            }
        },
        templates: [
            {
                title: "New Table",
                description: "creates a new table",
                content:
                    '<div class="mceTmpl"><table width="98%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
            },
            {
                title: "Starting my story",
                description: "A cure for writers block",
                content: "Once upon a time...",
            },
            {
                title: "New list with dates",
                description: "New List with dates",
                content:
                    '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: "67vh",
        image_caption: true,
        noneditable_noneditable_class: "mceNonEditable",
        contextmenu: "link image imagetools table",
        init_instance_callback: (editor) => {
            editor.on("ExecCommand", (e) => {
                console.log("The " + e.command + " command was fired.");
            });
        },
    };

    tinymceOptionsLight: any = {
        license_key: "gpl",
        menubar: false,
        invalid_elements: "input,textarea,select,option",
        paste_data_images: false,
        toolbar: "bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | numlist bullist",
        content_css: [
            "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i",
            "//www.tiny.cloud/css/codepen.min.css",
            "https://testmaster.blocworx.local/css/externals.css",
        ],
        content_style: `
            .mce-content-body p {
                margin: 6px 0px;
            }
            .skin-setting-dark.mce-content-body {
                background-color: #023038;
                color: #ffffff;
            }
            .skin-setting-bright.mce-content-body {
                background-color: #fff;
                color: #626262;
            }
        `,
        importcss_append: true,
        height: 190,
        image_caption: false,
    };

    setTinyMCETemplate() {
        const blocworxElement = document.getElementById("blocworx");

        this.tinymceOptions.body_class = "skin-setting-dark";
        this.tinymceOptionsLight.body_class = "skin-setting-dark";

        if (blocworxElement && blocworxElement.classList.contains("skin-setting-bright")) {
            this.tinymceOptions.body_class = "skin-setting-bright";
            this.tinymceOptionsLight.body_class = "skin-setting-bright";
        }
    }
}
