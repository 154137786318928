import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router, NavigationStart} from '@angular/router';

@Component({
    selector: 'data-edit-history-dialog',
    templateUrl: 'angular-blocworx/components/scanStation/data-edit-history/data-edit-history-dialog.html',
    styleUrls: ['angular-blocworx/components/scanStation/data-edit-history/data-edit-history-dialog.scss']
})
export class DataEditHistoryDialog {

    scanStation: any;
    convertedEditHistory: any;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DataEditHistoryDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(Router) private router: Router,
    ) {


        this.scanStation = data.scanStation;
        // Listen for route changes
        this.router.events.subscribe((event) => {
            // Check if the event is an instance of NavigationStart
            if (event instanceof NavigationStart) {
                this.closeDialog();
            }
        });
    }


    closeDialog(): void {
        this.dialogRef.close();
    }


    ngOnInit() {
        this.convertedEditHistory = this.convertObjectToEntries(this.data.dataEditHistoryRecord.data);

    }

    convertObjectToEntries(object:object): any[] {
        if (object != null) {
            return Object.entries(object);
        }
    }

    convertObjectToArray(object:object): any[] {
        if (object != null) {
            return Object.values(object);
        }
    }

    trackByFn(index: number, item: any): any {
        return item ? item[0] : index; // Use a unique property if available, otherwise fallback to index
    }


}