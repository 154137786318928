import { Component, Inject, OnInit, Input, Injectable } from "@angular/core";
import {
    IsVisibleService
} from "@/angular-blocworx/components/fields/wysiwyg-for-user/is-visible.service";
import {
    WysiwygForUserFieldServiceBLCXAU
} from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user.service";


@Component({
    selector: "wysiwyg-for-user-create-edit-field-popup",
    templateUrl: "angular-blocworx/components/fields/wysiwyg-for-user/templates/create-edit/popup.html",
    styleUrls: ["angular-blocworx/components/page/wysiwyg.component.scss"],
})

export class WysiwygForUserCreateEditFieldPopup implements OnInit {

    @Input() fc;
    @Input() scanStation;
    @Input() main;
    @Input() field;
    @Input() row;
    @Input() data;
    isChecked = true;

    constructor(
        @Inject(IsVisibleService) private IsVisibleService: IsVisibleService,
        @Inject(WysiwygForUserFieldServiceBLCXAU) private WysiwygForUserFieldServiceBLCXAU: WysiwygForUserFieldServiceBLCXAU
    ) {
    }

    ngOnInit(): void {

        this.scanStation.activeField = "wysiwyg-for-user";
        this.initializeField();
    }

    initializeField(): void {
        if (typeof this.fc.initialFieldParams.openEditorText === "undefined") {
            this.fc.initialFieldParams.openEditorText = "Open Editor";
        }
    }

    /**
     *
     * Controls visibility of parameters on the popup for field
     *
     * @param checked
     */
    onCheckboxChangeDefaultEditor(checked: boolean) {
        // Update the value based on the checkbox state
        this.fc.initialFieldParams.showEditorByDefault = checked ? 1 : 0;

        if (this.fc.initialFieldParams.showEditorByDefault == 1) {
            this.isChecked = false;
        } else {
            this.isChecked = true;
        }

        if (this.fc.initialFieldParams.hideEditButton == 1) {
            this.isChecked = false;
        }

        this.IsVisibleService.setValidationRequired(this.isChecked);

        this.WysiwygForUserFieldServiceBLCXAU.updateTrueOrFalse();

    }


    /**
     *
     * Controls visibility of parameters on the popup for field
     *
     * @param checked
     */
    onCheckboxChangeEditButton(checked: boolean) {

        this.fc.initialFieldParams.hideEditButton = checked ? 1 : 0;

        if (this.fc.initialFieldParams.hideEditButton == 1) {
            this.isChecked = false;
        } else {
            this.isChecked = true;
        }

        this.IsVisibleService.setValidationRequired(this.isChecked);

        this.WysiwygForUserFieldServiceBLCXAU.updateTrueOrFalse();

    }




}

