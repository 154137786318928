import { Component, OnInit, Injectable, Optional, Inject, Input, Injector, SimpleChanges } from "@angular/core";
import { PageService } from "@/angular-blocworx/components/page/page.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "edit-page-footer",

    templateUrl: "angular-blocworx/components/page/edit-page-footer/edit-page-footer.html",
})
@Injectable()
export class EditPageFooter {
    @Input() page;
    @Input() main;
    @Input() link;
    pageId: any;
    pageData: any = {};
    sectionsPagesModules: any;
    newSectionName: any;

    constructor(
        @Inject(PageService) private PageService: PageService,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        @Inject(Router) private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getPageData();
        this.pageId = this.pageData.id;
    }

    async ngAfterViewInit(): Promise<void> {}


    /**
     *
     * Adds a new page section
     *
     * @param newSectionName
     */
    async addNewPageSection(newSectionName): Promise<void> {
        await this.PageService.addNewPageSection(this.page.pageId, newSectionName);
        await this.getPageData();
    }

    /**
     *
     * Popup window for confirmation to delete page
     *
     */
    confirmAndDeletePage(): void {
        const confirmed = window.confirm("Are you sure you would like to delete this page?");

        if (confirmed) {
            this.deletePage(this.pageId);
        }
    }

    /**
     *
     * Deletes the page
     *
     * @param pageId
     */
    async deletePage(pageId: any): Promise<void> {
        await this.PageService.deletePage(pageId);
        alert("Page is now deleted, you will be redirected back to Home screen.");
        this.router.navigate(["/home"]);
    }

    /**
     *
     * Gets page data
     *
     */
    async getPageData(): Promise<void> {
        const response = await this.PageService.getPageData(this.page.stateName, this.page.pageId, this.page.pageSlug);
        this.page.sectionsPagesModules = response.sectionsPagesModules;
        this.pageData = response.pageData;

        this.page.sectionsPagesModules.forEach((section) => {
            section.links.forEach((link) => {
                if (link.link_type == "module" && this.page.stateName == "edit-page") {
                    link.ui_sref = ['module', link.id, 'edit'];
                }

                if (link.link_type == "module" && (this.page.stateName == "page" || this.page.stateName == "home")) {
                    link.ui_sref = ['module', link.slug];
                }

                if (link.link_type == "page" && this.page.stateName == "edit-page") {
                    link.ui_sref = ['page', link.id, 'edit'];
                }

                if (link.link_type == "page" && (this.page.stateName == "page" || this.page.stateName == "home")) {
                    link.ui_sref = ['page', link.slug];
                }

                if (link.link_type == "url" && this.page.stateName == "edit-page") {
                    link.ui_sref = ['url', link.id, 'edit'];
                }
            });
        });
    }
}
