import {Component, Inject, OnInit, Input} from '@angular/core';

@Component({
    selector: 'separator-field-create-edit-radio-button',
    templateUrl: 'angular-blocworx/components/fields/separator/templates/create-edit/radio-button.html',
    styleUrls: ['angular-blocworx/components/fields/separator/separator.component.scss'],
})

export class SeparatorFieldCreateEditRadioButton implements OnInit {

    @Input() fc;
    @Input() scanStation;

    ngOnInit(): void {

    }

    onCheckboxClick() {
        this.scanStation.showInitialFieldParameters = true;
        this.fc.updateShowCaseFieldType(this.fc.contentEditingComponents[0], 'contentEditingComponents');

    }

    onClick() {
        this.fc.initialFieldParams = {};
    }

}
