import { Component, OnInit, Injectable, Optional, Inject, Input, Injector, SimpleChanges } from "@angular/core";

import { PageService } from "@/angular-blocworx/components/page/page.service";

@Component({
    selector: "page-parent",
    templateUrl: "angular-blocworx/components/page/page.html",
})
@Injectable()
export class PageComponent {
    constructor(@Inject(PageService) private PageService: PageService) {}

    async ngOnInit(): Promise<void> {}
}
