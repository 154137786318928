import { Inject, Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})

/**
 * Service to pass data from popup component to service.ts to be used for validation
 */
export class IsVisibleService {
    constructor() {}

    validationRequired :boolean = true;


    /**
     *
     * Method to accept the data
     *
     * @param isChecked
     */
    setValidationRequired(isChecked: boolean): void {
        this.validationRequired = isChecked;
    }

    /**
     *
     * Method to process the data
     *
     */
    getValidationRequired(): boolean {
        return this.validationRequired;
    }

}

