import {Component, Inject, OnInit, Input } from '@angular/core';

@Component({
    selector: 'separator-field-in-data',
    templateUrl: 'angular-blocworx/components/fields/separator/templates/in-data.html',
    styleUrls: ['angular-blocworx/components/fields/separator/separator.component.scss'],

})

export class SeparatorFieldInData implements OnInit {

    @Input() field;

    ngOnInit(): void {

    }


}

