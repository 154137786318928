import {
    Component,
    Inject,
    Input,
    OnInit,
} from "@angular/core";
import { ManageDataService, SharedState } from "@/angular-blocworx/components/manageData/manageData.service";
import { FormControl } from "@angular/forms";
import { Observable, debounceTime, of } from "rxjs";
import { switchMap, startWith } from "rxjs/operators";
import { ChangeDetectorRef } from "@angular/core";


@Component({
    selector: "look-up-data",
    templateUrl: "angular-blocworx/components/manageData/look-up-data/look-up-data.html",
    styleUrls: ["angular-blocworx/components/manageData/look-up-data/look-up-data.scss"],
})

export class LookUpData {
    @Input() main;
    @Input() lc;


    autocompleteSearchForm = new FormControl("");
    filteredOptions: Observable<any[]>;

    fieldToSearch: any;
    dataToLookUp: string;
    noDataFound: boolean = false;
    dataManagerData: any;
    loadingSearch: boolean = false;
    jobToLookUp = null;
    specificSearch: boolean = false;
    allOptions: any[] = [];
    selectedCategoryToCheck: any;
    moduleCategory: any[] = [];
    sortBy: string;
    jobTypeToFilterBy: any;
    resultLimit: number = 1000;
    currentPage: number = 1;
    jobList: any[] = [];
    upperLimit: number;
    sortByOrder: string = "ASC";
    keepASCD_DESC_Settings: boolean = false;
    stateName: string = "someState";
    master: any;

    constructor(
        @Inject(ManageDataService) private ManageDataService: ManageDataService,
        @Inject(ChangeDetectorRef) private ChangeDetectorRef: ChangeDetectorRef,
    ) {

    }

    ngOnInit() {
        this.fetchAllOptions();
        // when the value of the autocomplete search changes the search will take place
        this.filteredOptions = this.autocompleteSearchForm.valueChanges.pipe(
            startWith(""),
            debounceTime(250),
            switchMap(value => this._filter(value)),
        );

        this.anyModuleInit();
        this.loadModuleCategory();
    }

    /**
     *
     * @private
     * Fetch all available field options for autocomplete
     */

    private async fetchAllOptions() {
        const data = await this.ManageDataService.searchByFieldName("");
        this.allOptions = data.fields || [];
    }

    /**
     *
     * @param event
     * Function to perform "looking up data"
     */
    async searchAction(event: any): Promise<void> {

        if (!this.dataToLookUp || this.dataToLookUp.trim() === "") {
            alert("Please enter a value to search");
            return;
        }

        try {
            this.noDataFound = false;
            this.dataManagerData = undefined;

            // Validate data before lookup
            this.validateDataToLookup(event);

            // Trigger search when 'Enter' key is pressed or button is clicked
            if (event) {
                if (event === "button") {
                    this.loadingSearch = true;
                    this.lc.loadingSearch = true;
                    const filterSlugToFilterBy = this.fieldToSearch?.field_slug || "";

                    // Fetch data from the service
                    const response = await this.ManageDataService.lookUpData(
                        this.dataToLookUp,
                        this.jobToLookUp,
                        filterSlugToFilterBy,
                        this.specificSearch,
                    );

                    this.loadingSearch = false;
                    this.lc.loadingSearch = false;
                    this.dataManagerData = response;
                    this.lc.dataManagerData = response;

                    // Update the shared state in the service
                    await this.updateSharedState({
                        dataManagerData: response,
                        dataToLookUp: this.dataToLookUp,
                    });
                }

            }

        } catch (exception: any) {
            if (exception) {
                console.log(exception.message);
                alert(exception.message);
            } else {
                console.log(exception.message || "An error occurred");
            }

        } finally {
            this.loadingSearch = false;
            this.lc.loadingSearch = false;
        }
    }

    /**
     *
     * @param newState
     * Update the shared state with new data
     */
    async updateSharedState(newState: Partial<SharedState>): Promise<void> {
        return new Promise((resolve) => {
            this.ManageDataService.setState(newState);
            resolve();
        });
    }

    /**
     *
     * @param event
     * Validate the data before performing the lookup
     */
    validateDataToLookup(event: KeyboardEvent): boolean {
        // Prevent error when 'Backspace' (keyCode 8) to nothing
        if (event && event.keyCode === 8) {
            return true;
        }

        // Check if `dataToLookUp` is undefined or empty
        if (!this.dataToLookUp || this.dataToLookUp.trim() === "") {
            console.log("data to lookup");
        }
    }

    /**
     *
     * @param option
     * Set the selected field to be used in the search
     */
    selectedField(option: string) {
        this.fieldToSearch = option;
    }

    /**
     * Reset the job selection when 'Any' module is selected
     */
    anyModuleClick() {
        this.noDataFound = false;
        this.jobToLookUp = null;
    }

    /**
     * Initialize the settings when 'Any' module is selected
     */
    anyModuleInit() {
        this.selectedCategoryToCheck = "any";
        this.jobToLookUp = null;
    }

    /**
     * Load the list of jobs based on sorting and filtering
     */
    async loadJobs() {
        this.sortBy = "title";
        await this.getUsersJobs(this.sortBy, this.jobTypeToFilterBy);
    }

    /**
     * Initialize the specific module filtering based on selected status
     * @param status
     */
    async specificModuleInit(status) {
        this.noDataFound = false;
        this.sortBy = "title";
        this.jobTypeToFilterBy = status.status;
        await this.getUsersJobs(this.sortBy, this.jobTypeToFilterBy);
        this.jobList = [];
        this.jobToLookUp = null;
    }

    /**
     *
     * @param sortBy
     * @param jobTypeToFilterBy
     * Fetch the list of jobs with the given filters and sorting parameters
     */
    async getUsersJobs(sortBy: string, jobTypeToFilterBy: any): Promise<void> {
        try {
            this.sortBy = sortBy;

            if (typeof this.resultLimit === "undefined") {
                this.resultLimit = 10;
            }

            if (typeof this.currentPage === "undefined") {
                this.currentPage = 1;
            }

            const data = await this.ManageDataService.getUsersJobs(
                this.sortBy,
                this.master,
                this.sortByOrder,
                jobTypeToFilterBy,
                this.resultLimit,
                this.currentPage,
                this.stateName,
            );

            this.jobList = data.data.jobs;
            this.upperLimit = data.data.total;

            if (!this.keepASCD_DESC_Settings) {
                this.sortByOrder = this.sortByOrder === "ASC" ? "DESC" : "ASC";
            }

            this.keepASCD_DESC_Settings = false;

            if (typeof this.jobTypeToFilterBy === "undefined" && this.jobList.length > 0) {
                this.jobTypeToFilterBy = this.jobList[0].activity_status;
            }

            this.ChangeDetectorRef.detectChanges();

            console.log(this.jobList);
            console.log(this.upperLimit);

        } catch (e) {
            console.error(e);
        }
    }


    /**
     * Load the module categories to filter the job list
     */
    async loadModuleCategory(): Promise<void> {
        try {
            let response = await this.ManageDataService.getModuleCategory();
            this.moduleCategory = response.data;
        } catch (e) {
            console.error(e);
        } finally {
            this.ChangeDetectorRef.detectChanges();
        }
    }


    /**
     *
     * @param value
     * @private
     * Filter the autocomplete options based on the input value
     */
    private async _filter(value: string): Promise<any[]> {
        if (!value) {
            return this.allOptions;
        }

        let fields: any[] = [];

        const data = await this.ManageDataService.searchByFieldName(value);
        if (data?.fields) {
            fields = data.fields;
        }
        return fields;

    }

    /**
     * Reset the autocomplete field when clicked
     */
    getAllFields() {
        if (!this.autocompleteSearchForm.value) {
            this.autocompleteSearchForm.setValue("");
        }
    }

    /**
     * Clear the autocomplete search and reset the field
     */
    clearSearch() {
        this.autocompleteSearchForm.setValue("");
        this.filteredOptions = of(this.allOptions);
        this.fieldToSearch = null;
    }


}
