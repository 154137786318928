import {Inject, Injectable} from '@angular/core';
import * as angular from "angular";
import {BaseFieldService} from "@/angular-blocworx/components/fields/BaseFieldService";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Configuration} from "@/angular-blocworx/components/configuration/services/configuration.service";
import {RequestService} from "@/angular-blocworx/components/configuration/services/request.service";
import {lastValueFrom} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class GridService extends BaseFieldService {

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {
        super(http, configuration, request);
    }

    public scanStationFormValidate =  (scanStation, field) => {

        try {

            if (scanStation.showHiddenFields == 1) {
                return true;
            }

            if (field === undefined || scanStation === undefined) {
                return false;
            }

            let conditionalShowField = null;
            let checkConditionalIncluded = null;
            let checkConditionalBlocworxAnyWord = null;


            // new multi field show condition takes priority and overwrites legacy one

            if (field.multiFieldShowConditions != null && Object.keys(field.multiFieldShowConditions).length > 0) {
                let someOrAllMustEqual = 'all';

                if (field.multipleShowConditionAllOrSome != null) {
                    someOrAllMustEqual = field.multipleShowConditionAllOrSome;
                }

                // all fields must equal the values so it fails if at least one doesnt match
                if (someOrAllMustEqual == 'all') {

                    let showConditionPasses = true;
                    let dataGroups = {};
                    let i = 0;
                    angular.forEach(field.multiFieldShowConditions, function (showCondition) {

                        if (showCondition != null) {
                            if (dataGroups['_' + showCondition.localFieldId] == null) {
                                dataGroups['_' + showCondition.localFieldId] = {};
                            }

                            dataGroups['_' + showCondition.localFieldId][i] = showCondition.valueItMustEqual;
                        }
                        i++;
                    });

                    let newDataGroups = {}

                    angular.forEach(dataGroups, function (group, index) {
                        newDataGroups[index] = Object.values(group);
                    });


                    angular.forEach(field.multiFieldShowConditions, function (showCondition) {
                        if (showCondition != null) {
                            if(scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId] == null) {
                                showConditionPasses = false;
                            } else if (newDataGroups['_' + showCondition.localFieldId].includes('[blocworx-any-word]')) {
                                if (
                                    scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId] == null ||
                                    (scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId] != null &&
                                        (scanStation.scanStationObj.dataToAdd[scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId].field_slug] == null || scanStation.scanStationObj.dataToAdd[scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId].field_slug] == ''))
                                ) {
                                    showConditionPasses = false;
                                }
                                // check if it can be between two values
                            } else if (newDataGroups['_' + showCondition.localFieldId].filter(s => s.includes('between:')).length>0) {
                                angular.forEach(newDataGroups['_' + showCondition.localFieldId], function (value) {
                                    if (value.includes('between:')) {
                                        value = value.replace('between:','');
                                        let numberSplit = value.split(',');
                                        let lowerNumber = parseFloat(numberSplit[0]);
                                        let higherNumber = parseFloat(numberSplit[1]);
                                        let numberToCheck = parseFloat(scanStation.scanStationObj.dataToAdd[scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId].field_slug]);
                                        if (isNaN(lowerNumber) || isNaN(higherNumber) || isNaN(numberToCheck) || numberToCheck < lowerNumber || numberToCheck > higherNumber) {
                                            showConditionPasses = false;
                                        }
                                    }
                                });

                            } else {
                                // convert it to a string
                                let valueToCheck = `${scanStation.scanStationObj.dataToAdd[scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId].field_slug]}`;

                                if (!newDataGroups['_' + showCondition.localFieldId].includes(valueToCheck)) {
                                    showConditionPasses = false;
                                }
                            }
                        }
                    });

                    if (field.multiFieldShowConditionShowOrHide == null || field.multiFieldShowConditionShowOrHide == 'show') {
                        return showConditionPasses;
                    } else {
                        return !showConditionPasses;
                    }
                }

                // only some fields need to equal the values to it passes if at least one does match
                if (someOrAllMustEqual == 'some') {

                    let showConditionPasses = false;

                    angular.forEach(field.multiFieldShowConditions, function (showCondition) {

                            if (showCondition != null) {
                                if (showCondition.valueItMustEqual == '[blocworx-any-word]') {
                                    if (scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId] != null && scanStation.scanStationObj.dataToAdd[scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId].field_slug] != null && scanStation.scanStationObj.dataToAdd[scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId].field_slug] != '') {
                                        showConditionPasses = true;
                                    }
                                }
                                else if (scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId] != null && showCondition.valueItMustEqual.includes('between:')) {
                                    let extractedNumbersString = showCondition.valueItMustEqual.replace('between:','');
                                    let numberSplit = extractedNumbersString.split(',');
                                    let lowerNumber = parseFloat(numberSplit[0]);
                                    let higherNumber = parseFloat(numberSplit[1]);
                                    let numberToCheck = parseFloat(scanStation.scanStationObj.dataToAdd[scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId].field_slug]);

                                    if (!isNaN(lowerNumber) && !isNaN(higherNumber) && !isNaN(numberToCheck) && numberToCheck >= lowerNumber && numberToCheck <= higherNumber) {
                                        showConditionPasses = true;
                                    }
                                }
                                else if (scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId] != null && showCondition.valueItMustEqual == scanStation.scanStationObj.dataToAdd[scanStation.scanStationObj.scanStationFieldIdsToField[showCondition.localFieldId].field_slug]) {
                                    showConditionPasses = true;
                                }
                            }
                        }
                    );

                    if (field.multiFieldShowConditionShowOrHide == null || field.multiFieldShowConditionShowOrHide == 'show') {
                        return showConditionPasses;
                    } else {
                        return !showConditionPasses;
                    }
                }
            }

            // making sure that conditionalShowFieldSlug exists
            if ('conditionalShowFieldSlug' in field) {
                conditionalShowField = scanStation.scanStationObj.dataToAdd[field.conditionalShowFieldSlug];
            }

            // if the data is disabled, must hid this field
            if (scanStation.scanStationObj.stationDetails.disable_data === 1) {
                return false;
            }

            // If the field status is different from 1 (the show status)
            if (field.status !== 1) {
                return false;
            }

            if (field.conditionallyShow == 'show') {

                return true;
            }


            // check of the included data is present into the field.conditionalShowParameters
            if ('conditionalShowParameters' in field && field.conditionalShowParameters != false) {
                let secondCheck = conditionalShowField;

                if (Number.isInteger(conditionalShowField)) {
                    secondCheck = conditionalShowField.toString();
                }

                checkConditionalIncluded = field.conditionalShowParameters.includes(conditionalShowField) || field.conditionalShowParameters.includes(secondCheck);
                checkConditionalBlocworxAnyWord = field.conditionalShowParameters.includes('[blocworx-any-word]');
            }

            // check that verifies if the parameter is inside o the conditionalShowParameters
            if (checkConditionalIncluded === true) {
                return true;
            }

            return checkConditionalBlocworxAnyWord && conditionalShowField != null && conditionalShowField != '';

        }
        catch
            (e) {
            console.log(e);
        }

    }


}