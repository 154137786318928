import {Component, Inject, OnInit, Input} from '@angular/core';
import {
    IsVisibleService
} from "@/angular-blocworx/components/fields/wysiwyg-for-user/is-visible.service";
import {
    WysiwygForUserFieldServiceBLCXAU
} from "@/angular-blocworx/components/fields/wysiwyg-for-user/wysiwyg-for-user.service";

@Component({
    selector: 'wysiwyg-for-user-create-edit-field-radio-button',
    templateUrl: 'angular-blocworx/components/fields/wysiwyg-for-user/templates/create-edit/radio-button.html',
    styleUrls: ["angular-blocworx/components/page/wysiwyg.component.scss"],

})


export class WysiwygForUserCreateEditFieldRadioButton implements OnInit {

    @Input() fc;
    @Input() scanStation;
    @Input() main;
    @Input() field;
    @Input() row;
    @Input() data;
    isChecked: boolean = true;

    constructor(
        @Inject(IsVisibleService) private IsVisibleService: IsVisibleService,
        @Inject(WysiwygForUserFieldServiceBLCXAU) private WysiwygForUserFieldServiceBLCXAU: WysiwygForUserFieldServiceBLCXAU
    )
    {

    }


    ngOnInit(): void {

    }

    /**
     *
     * When you open the popup, this ensures that all the parameters are visible in the popup
     *
     */
    onCheckboxClick() {
        this.isChecked = true;

        // Update the validationRequired
        this.IsVisibleService.setValidationRequired(this.isChecked);

        // Update the trueOrFalse
        this.WysiwygForUserFieldServiceBLCXAU.updateTrueOrFalse();

    }

}
