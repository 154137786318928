import {
    Component,
    OnInit,
    Injectable,
    Optional,
    Inject,
    Input,
    Injector,
    SimpleChanges,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ManageDataService } from "@/angular-blocworx/components/manageData/manageData.service";
import { OverlayContainer } from "@angular/cdk/overlay";
import { ReplacedData } from "@/angular-blocworx/components/manageData/find-and-replace/replaced-data.component";

@Component({
    selector: "replace-confirmation",
    templateUrl: "angular-blocworx/components/manageData/find-and-replace/replace-confirmation.html",
})

export class ReplaceConfirmation {
    @Input() main;
    valueToSearch: string = "";
    valueToReplace: string = "";
    emailReplaceConfirm: string = "";
    results: any[] = [];
    filteredResults: any[] = [];


    constructor(
        @Inject(ManageDataService) private ManageDataService: ManageDataService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ReplaceConfirmation>,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(OverlayContainer) private overlayContainer: OverlayContainer,
    ) {
        this.valueToSearch = data.valueToSearch;
        this.valueToReplace = data.valueToReplace;

    }

    async ngOnInit(): Promise<void> {
    }

    /**
     *
     * In the records, replaces all values equal to valueToSearch with valueToReplace
     *
     */
    async performReplacement() {
        this.results = await this.ManageDataService.replaceValue(this.valueToSearch, this.valueToReplace);
        this.filterResults();
        this.openReplacedDataDialog();
    }

    /**
     *
     * Returns data records that match valueToSearch
     *
     */
    filterResults() {
        this.filteredResults = this.results.filter(result => {
            try {
                const parsedResult = JSON.parse(result);
                return Object.values(parsedResult).some(value => value === this.valueToSearch);
            } catch (e) {
                console.error("Error parsing JSON:", e);
                return false;
            }
        });
    }

    /**
     *
     * Opens a popup that will display the records that contain valueToSearch
     *
     */
    openReplacedDataDialog() {
        const dialogRef = this.dialog.open(ReplacedData, {
            data: {
                filteredResults: this.filteredResults,
            },
        });

        this.overlayContainer.getContainerElement().classList.add("blocworx-popup");
    }

    cancel() {
        this.dialogRef.close();
    }

    /**
     *
     * In the records, replaces all values equal to valueToSearch with valueToReplace
     * Opens a popup that will show the data records that are to be replaced
     * If user enters an email, an email will be sent when the values are completely replaced
     *
     */
    async confirm() {
        await this.performReplacement();
        this.ManageDataService.replaceConfirmation(this.emailReplaceConfirm, this.valueToSearch, this.valueToReplace, this.filteredResults);
        this.dialogRef.close(true);
    }

}
