import { Injectable, Inject } from "@angular/core";
import { Configuration } from "@/angular-blocworx/components/configuration/services/configuration.service";
import { RequestService } from "@/angular-blocworx/components/configuration/services/request.service";
import { HttpClient } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class PageService {
    protected apiUrl: string;

    private textSource = new BehaviorSubject<string>('');
    private titleSource = new BehaviorSubject<string>('');

    currentText = this.textSource.asObservable();
    currentTitle = this.titleSource.asObservable();

    constructor(
        @Inject(HttpClient) protected http: HttpClient,
        @Inject(Configuration) protected configuration: Configuration,
        @Inject(RequestService) protected request: RequestService
    ) {
        this.configuration.ngOnInit();
        this.apiUrl = this.configuration.getApiUrl();
    }

    /**
     *
     * Updates text in real time using observable
     *
     * @param newText
     */
    updateText(newText: string) {
        this.textSource.next(newText);
    }

    /**
     *
     * Updates title in real time using observable
     *
     * @param newTitle
     */
    updateTitle(newTitle: string) {
        this.titleSource.next(newTitle);
    }


    // HTTP Requests

    public addNewPage = async (newPageData): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { newPageData };

        let response = this.http.post(`${this.apiUrl}add-new-page`, params, { headers });

        return lastValueFrom(response);
    };

    public deletePage = async (pageId): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { pageId };

        let response = this.http.delete(`${this.apiUrl}delete-page`, { params, headers });

        return lastValueFrom(response);
    };

    public getPageSections = async (pageId): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { pageId };

        let response = this.http.get(`${this.apiUrl}get-page-sections`, { params, headers });

        return lastValueFrom(response);
    };

    public addNewPageSection = async (pageId, sectionName): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { pageId, sectionName };

        let response = this.http.post(`${this.apiUrl}add-new-page-section`, params, { headers });

        return lastValueFrom(response);
    };

    public deletePageSection = async (sectionID, pageId): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { sectionID, pageId };

        let response = this.http.delete(`${this.apiUrl}delete-page-section`, { params, headers });

        return lastValueFrom(response);
    };

    public updatePageSectionName = async (newSectionName, sectionID): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { newSectionName, sectionID };

        let response = this.http.post(`${this.apiUrl}update-page-section-name`, params, { headers });

        return lastValueFrom(response);
    };

    public addSubPage = async (subPageName, sectionID, pageId, orderID, newLinkIcon, newRoles, newPageOpenAccess): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { subPageName, sectionID, pageId, orderID, newLinkIcon, newRoles, newPageOpenAccess };

        let response = this.http.post(`${this.apiUrl}add-sub-page`, params, { headers });

        return lastValueFrom(response);
    };

    public addModule = async (moduleName, newModuleCategory, sectionID, pageId, orderID, newLinkIcon): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { moduleName, newModuleCategory, sectionID, pageId, orderID, newLinkIcon };

        let response = this.http.post(`${this.apiUrl}add-module`, params, { headers });

        return lastValueFrom(response);
    };

    public addLinkUrl = async (newLinkName, newLinkUrl, sectionID, pageId, orderID, newLinkIcon, newRoles, newTab): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { newLinkName, newLinkUrl, sectionID, pageId, orderID, newLinkIcon, newRoles, newTab };

        let response = this.http.post(`${this.apiUrl}add-link-url`, params, { headers });

        return lastValueFrom(response);
    };

    public deleteLinkUrl = async (id): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { id };

        let response = this.http.delete(`${this.apiUrl}delete-link-url`, { params, headers });

        return lastValueFrom(response);
    };

    public addExistingModuleToPage = async (moduleId, sectionId, moduleIcon): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { moduleId, sectionId, moduleIcon };

        let response = this.http.post(`${this.apiUrl}add-existing-module-to-page`, params, { headers });

        return lastValueFrom(response);
    };

    public getModulePageAndSection = async (moduleId): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { moduleId };

        let response = this.http.get(`${this.apiUrl}get-module-page-and-section`, { params, headers });

        return lastValueFrom(response);
    };

    public reorderSections = async (direction, sectionId, pageId): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { direction, sectionId, pageId };

        let response = this.http.post(`${this.apiUrl}reorder-page-sections`, params, { headers });

        return lastValueFrom(response);
    };

    public increasePageOrder = async (sectionId, pageId): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { sectionId, pageId };

        let response = this.http.post(`${this.apiUrl}increase-page-order`, params, { headers });

        return lastValueFrom(response);
    };

    public decreasePageOrder = async (sectionId, pageId): Promise<any> => {
        let headers = this.request.getHeaders();

        let params = { sectionId, pageId };

        let response = this.http.post(`${this.apiUrl}decrease-page-order`, params, { headers });

        return lastValueFrom(response);
    };

    public getRolesAndPermissions = async (): Promise<any> => {
        let headers = this.request.getHeaders();

        const params = {};

        let response = this.http.get(`${this.apiUrl}get-roles-and-permissions`, { params, headers });

        return lastValueFrom(response);
    };

    public updatePage = async (pageData): Promise<any> => {
        let headers = this.request.getHeaders();

        const params = { pageData };

        let response = this.http.post(`${this.apiUrl}update-page`, params, { headers });

        return lastValueFrom(response);
    };

    public getPages = async (): Promise<any> => {
        let headers = this.request.getHeaders();

        const params = {};

        let response = this.http.get(`${this.apiUrl}get-pages`, { params, headers });

        return lastValueFrom(response);
    };

    public getCountPageSections = async (pageId, pageSlug): Promise<any> => {
        let headers = this.request.getHeaders();

        const params = { pageId, pageSlug };

        let response = this.http.get(`${this.apiUrl}get-count-page-sections`, { params, headers });

        return lastValueFrom(response);
    };

    public getPageData = async (stateName, pageId, pageSlug): Promise<any> => {
        let headers = this.request.getHeaders();

        const params = { stateName, pageId, pageSlug };

        let response = this.http.get(`${this.apiUrl}get-page-data`, { params, headers });

        return lastValueFrom(response);
    };
}
