import { Component, Inject, OnInit, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { SeparatorFieldService } from "@/angular-blocworx/components/fields/separator/separator.service";

@Component({
    selector: "separator-field-in-form",
    templateUrl: "angular-blocworx/components/fields/separator/templates/in-form.html",
    styleUrls: ["angular-blocworx/components/fields/separator/separator.component.scss"],
})

export class SeparatorFieldInForm implements OnInit {

    @Input() field;
    @Input() fc;
    @Input() scanStation;
    htmlContent: SafeHtml;

    constructor(@Inject(DomSanitizer) private sanitizer: DomSanitizer, @Inject(SeparatorFieldService) private SeparatorFieldService: SeparatorFieldService) {
    }

    ngOnInit(): void {

        this.htmlContent = this.sanitizeContent(this.field.separatorDefaultText);

    }

    public sanitizeContent(content: string): SafeHtml {
        if (!content) {
            return "";
        }
        return this.sanitizer.bypassSecurityTrustHtml(content);
    }

}
